html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #4d5659;
  text-rendering: optimizeSpeed;
  padding-bottom: 80px;
}
a {
  color: #006e92;
}
ul ul {
  padding-left: 20px;
}
ul ul li {
  list-style-type: disc;
}
main {
  padding: 20px;
  max-width: 600px;
  margin: auto;
}
.center {
  text-align: center;
  margin: 0px auto;
}
.border {
  border: 1px solid rgba(0,0,0,0.2);
}
.recent-posts h3 {
  font-size: 1.5em;
}
.recent-posts article {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  padding-bottom: 20px;
  max-width: 100%;
}
.recent-posts article * {
  max-width: 100%;
}
.recent-posts article blockquote {
  font-style: italic;
  margin: 0;
  padding: 0 20px;
}
.recent-posts article time {
  color: #555;
}
.recent-posts article h1 {
  font-size: 2em;
  margin: 0;
  font-weight: 300;
  line-height: 1.2;
}
.recent-posts article h1 a {
  text-decoration: none;
}
.recent-posts article h2,
.recent-posts article h3,
.recent-posts article h4,
.recent-posts article h5 {
  font-size: 1em;
}
.recent-posts article:last-of-type {
  border: none;
}
.post-list li {
  list-style-type: none;
}
.post-list time {
  display: inline-block;
  width: 120px;
  vertical-align: top;
}
.post-list a {
  display: inline-block;
  max-width: 440px;
  min-width: 180px;
}
@media (max-width: 480px) {
  .post-list a {
    max-width: 200px;
  }
}
header h2 {
  font-size: 32px;
  padding-left: 20px;
  margin-top: -51px;
  color: #fff;
}
header p {
  padding-left: 20px;
  margin-top: -20px;
  font-size: 16px;
}
header p:first-of-type {
  margin-top: -20px;
}
header div {
  width: 100%;
  overflow: hidden;
}
header div img {
  width: auto;
}
@media screen and (max-width: 480px) {
  header div {
    height: 120px;
  }
  header div img {
    height: 160px;
  }
}
@media screen and (min-width: 481px) and (max-width: 560px) {
  header div {
    height: 140px;
  }
  header div img {
    height: 190px;
  }
}
@media screen and (min-width: 561px) and (max-width: 660px) {
  header div {
    height: 160px;
  }
  header div img {
    height: 220px;
  }
}
@media screen and (min-width: 661px) and (max-width: 760px) {
  header div {
    height: 220px;
  }
  header div img {
    height: 260px;
  }
}
@media screen and (min-width: 761px) and (max-width: 860px) {
  header div {
    height: 240px;
  }
  header div img {
    height: 300px;
  }
}
@media screen and (min-width: 861px) and (max-width: 1000px) {
  header div {
    height: 280px;
  }
  header div img {
    height: 340px;
  }
}
@media screen and (min-width: 1001px) and (max-width: 1200px) {
  header div {
    height: 380px;
  }
  header div img {
    height: 400px;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1420px) {
  header div {
    height: 420px;
  }
  header div img {
    height: 480px;
  }
}
@media screen and (min-width: 1421px) and (max-width: 1600px) {
  header div {
    height: 420px;
  }
  header div img {
    height: 540px;
  }
}
@media screen and (min-width: 1601px) {
  header div {
    height: 420px;
  }
  header div img {
    height: 600px;
  }
}
.post-wrapper {
  overflow: hidden;
}
.post-wrapper * {
  max-width: 100%;
}
.post-wrapper aside {
  color: #555;
}
.post-wrapper hr {
  border: 1px solid #ccc;
}
.post-wrapper h1 {
  font-size: 2em;
  margin: 0;
  font-weight: 300;
  line-height: 1.2;
}
.post-wrapper h1 a {
  text-decoration: none;
  color: #4d5659;
}
.post-wrapper h1 a:hover {
  color: #006e92;
}
.post-wrapper h2,
.post-wrapper h3,
.post-wrapper h4,
.post-wrapper h5,
.post-wrapper h6 {
  font-weight: 300;
}
.post-wrapper a.anchor {
  text-decoration: none;
  color: #4d5659;
}
.post-wrapper a.anchor:hover {
  color: #006e92;
  text-decoration: underline;
}
.post-wrapper blockquote {
  margin: 1em;
}
.post-wrapper blockquote blockquote {
  margin-left: 0;
  font-size: 2em;
  font-weight: 600;
  color: #006e92 !important;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
.post-wrapper blockquote blockquote p {
  margin: 0;
  line-height: 1.2;
}
.post-wrapper pre {
  background: #444;
  line-height: 1;
  padding: 5px;
  overflow-x: scroll;
}
.post-wrapper pre code {
  font-size: 0.75em;
  color: #fff;
}
.post-wrapper code {
  background: #444;
  padding: 0 2px;
  color: #fff;
}
